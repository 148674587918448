import {inject, observer} from "mobx-react";
import Player from "./Square/Player";

const Square = ({store, position, top, right, bottom, left}) => {

   const boxShadow = {};

    if (right) {
        boxShadow.boxShadow = `-5px 0px 0px 0px ${store.board[position].color}`
    }
    if (left) {
        boxShadow.boxShadow = `5px 0px 0px 0px ${store.board[position].color}`
    }
    if (top) {
        boxShadow.boxShadow = `0px 5px 0px 0px ${store.board[position].color}`
    }
    if (bottom) {
        boxShadow.boxShadow = `0px -5px 0px 0px ${store.board[position].color}`
    }

    return (
        <div style={{...boxShadow}} className={`d-flex flex-wrap align-content-between justify-content-between game-tile square-img-${position} ${store.board[position].mortgaged ? "grey-card" : ""}`}>

            {store.players.filter(player => player.currentSquare === position).map((player, i) => {
                return <Player playerNumber={player.index} key={i}/>
            })}

        </div>
    )
}

export default inject("store")(observer(Square));
