import {Form} from "react-bootstrap";

const FormTextName = ({name, handleChange, disabled}) => {
    return (
        <>
            <Form.Label htmlFor="inputCode">Ваше имя</Form.Label>
            <Form.Control placeholder="Ваше имя" type="text" value={name} onChange={(e)=>{handleChange(e.target.value)}} disabled={disabled} />
        </>
    );
}

export default FormTextName;
