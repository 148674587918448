import {inject, observer} from "mobx-react";
import RollDice from "./Actions/RollDice";
import EndTurn from "./Actions/EndTurn";

const Actions = ({store}) => {
    return (
        <div className="action-card">
            <RollDice/>
        </div>
    )
}
export default inject("store")(observer(Actions));
