import { Row, Col } from 'react-bootstrap';
import Log from "./Main/Log";
import Actions from "./Main/Actions";
import Players from "./Main/Players";

export default function Main() {
    return (
        <div className="main-view">
            <div className="main-view-content">
                <Row>
                    <Col xs={8}><Actions /></Col>
                    <Col xs={4}><Players /></Col>
                </Row>

                <Row>
                    <Col xs={12}><Log /></Col>
                </Row>
            </div>
        </div>
    )
}
