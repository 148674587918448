import {Button, Card, Col, Container, ListGroup, Row} from 'react-bootstrap';
import React, {useEffect} from "react";
import {inject, observer} from "mobx-react";
import { useNavigate } from "react-router-dom";
import SelectColor from "./Start/SelectColor";
import SelectToken from "./Start/SelectToken";
import FormTextName from "./Start/FormTextName";

const Lobby = ({store}) => {
    const navigate = useNavigate();
    const handleChangeName = (value) => {
        store.setName(value);
        store.sendPlayerInfo();
    }

    const handleChangeColor = (value) => {
        store.setColor(value);
        store.sendPlayerInfo();
    }

    const handleChangeToken = (value) => {
        store.setToken(value);
        store.sendPlayerInfo();
    }

    useEffect(() => {
        store.reconnectToGameSocket();
    }, []);

    const handleClickStart = () => {
        store.actionStartGame();
        //navigate("/game");
    }

    return (
        <Container style={{ width: '52rem' }} className="p-4">

            <Card>
                <Card.Header className="d-flex justify-content-between align-items-start">
                    <div>Лобби</div>
                    <div><b>{store.gameCode}</b></div>
                </Card.Header>

                <Card.Body>
                    <Row>
                        <Col>
                            <ListGroup>
                                {store.players.map((p, i) => {
                                    return (
                                        <ListGroup.Item key={i}
                                                        variant={store.players[i].index === store.player.index ? "warning" : ""}
                                                        className="d-flex justify-content-between align-items-start"
                                                        style={{borderLeft: `15px solid ${store.colors[store.players[i].color]}`}}>

                                            <div>{store.tokens[store.players[i].token]} <small>{p.name}</small></div>

                                            <div><i className={store.players[i].acceptedConfiguration === true ? `bi bi-check-square` : `bi bi-square`}></i></div>

                                        </ListGroup.Item>
                                    )
                                })}
                            </ListGroup>
                            <div>{store.numberOfPlayer} из {store.config.maxPlayers}</div>
                        </Col>

                        <Col>
                            <Row><Col><FormTextName name={store.player.name} handleChange={(value)=>{handleChangeName(value)}} disabled={store.player.acceptedConfiguration} /></Col></Row>
                            <Row className="mt-3"><Col><SelectColor color={store.player.color} handleChange={(value)=>{handleChangeColor(value)}} disabled={true} /></Col></Row>
                            <Row className="mt-3"><Col><SelectToken token={store.player.token} handleChange={(value)=>{handleChangeToken(value)}} disabled={store.player.acceptedConfiguration} /></Col></Row>
                        </Col>
                    </Row>
                </Card.Body>

                <Card.Footer className="d-flex justify-content-between align-items-start">
                    <Button variant="primary" onClick={handleClickStart} hidden={store.player.index !== 0} disabled={store.players.length < 2}>Начать игру</Button>

                    <Button variant="warning" onClick={() => {
                        store.actionConfirmPlayer();
                    }}>
                        { store.player.acceptedConfiguration === true ? `Вернуться к редактированию` : `Подтвердить участие` }
                    </Button>
                </Card.Footer>
            </Card>

        </Container>
    )
}

export default inject("store")(observer(Lobby));
