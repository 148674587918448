import {Form} from "react-bootstrap";

const SelectColor = ({color, handleChange, disabled}) => {
    return (
        <>
            <Form.Label htmlFor="selectColor">Цвет</Form.Label>
            <Form.Select id="selectColor" value={color} onChange={(e)=>{handleChange(e.target.value)}} disabled={disabled}>
                <option>Выберите свой цвет</option>
                <option value="PC_RED">🟥</option>
                <option value="PC_ORANGE">🟧</option>
                <option value="PC_YELLOW">🟨</option>
                <option value="PC_GREEN">🟩</option>
                <option value="PC_BLUE">🟦</option>
                <option value="PC_MAGENTA">🟪</option>
            </Form.Select>
        </>
    );
}

export default SelectColor;
