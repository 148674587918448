import {runInAction, makeAutoObservable} from "mobx";
import axios from "axios";
import {v4 as uuidv4} from "uuid";
import {io} from "socket.io-client"
import { toast } from "react-toastify";

const SERVER_URI = process.env.REACT_APP_SERVER_URI;
const SERVER_API_PROTOCOL = process.env.REACT_APP_SERVER_API_PROTOCOL;
const SERVER_WS_PROTOCOL = process.env.REACT_APP_SERVER_WS_PROTOCOL;

class Store {
    socket = io(SERVER_WS_PROTOCOL+SERVER_URI);

    uuid = undefined;
    deviceId = undefined;
    config = undefined;
    board = undefined;
    logs = [];

    currentAction = 'END_TURN'; // END_TURN PLEASE_ROLL_DICE
    currentPlayer = undefined;

    gamePhase = undefined;
    gameUUID = undefined;
    gameCode = undefined;
    diceOne = 0;
    diceTwo = 0;

    player = {
        index: 0,
        cash: undefined,
        name: undefined,
        token: undefined,
        color: undefined,
        currentSquare: undefined,
        acceptedConfiguration: false
    }

    numberOfPlayer = 0;

    players = [];

    tokens = {
        TK_CROWN: "👑",
        TK_JEEP: "🚓",
        TK_LION: "🦁",
        TK_PLANE: "✈",
        TK_SHIP: "⛵",
        TK_CAR: "🚗"
    }

    colors = {
        PC_RED: "#FF0000",
        PC_ORANGE: "#FFA500",
        PC_YELLOW: "#FFFF00",
        PC_GREEN: "#008000",
        PC_BLUE: "#0000FF",
        PC_MAGENTA: "#FF00FF"
    }

    action = {
        ACTION_NAME_PLAYER: 9,
        ACTION_ACCEPT_CONFIGURATION: 10,
        ACTION_START_GAME: 11
    }

    constructor() {
        axios.defaults.baseURL = SERVER_API_PROTOCOL+SERVER_URI;
        axios.defaults.headers.post["Content-Type"] = "application/json";
        axios.defaults.headers.get["Accept"] = "application/json";

        this.socket.on("error", (...error) => {
            this.processError(error);
        });

        this.socket.on("state", (...state) => {
            this.processState(state);
        });

        this.socket.on("message", (...message) => {
            this.processMessage(message);
        });

        this.socket.on("log", (...log) => {
            this.processLog(log);
        });

        makeAutoObservable(this);
        this.uuid = uuidv4();

        if (!localStorage.hasOwnProperty("deviceId")) {
            this.deviceId = uuidv4();
            localStorage.setItem("deviceId", this.deviceId);
        } else {
            this.deviceId = localStorage.getItem("deviceId")
        }

        if (!localStorage.hasOwnProperty("playerName")) {
            this.setRandomName();
        } else {
            this.player.name = localStorage.getItem("playerName")
        }

        if (!localStorage.hasOwnProperty("playerToken")) {
            this.setRandomToken();
        } else {
            this.player.token = localStorage.getItem("playerToken")
        }

        if (!localStorage.hasOwnProperty("playerColor")) {
            this.setRandomColor();
        } else {
            this.player.color = localStorage.getItem("playerColor")
        }

        if (!this.config) {
            this.config = this.apiGetConfig();
        }
    }

    toastError(message) {
        toast.error(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }

    apiGetConfig = async () => {
        try {
            const response = await axios.get("/config");
            runInAction(() => {
                this.config = {
                    colors: response.data.colors,
                    tokens: response.data.tokens,
                    maxPlayers: response.data.maxPlayers,
                    rollDiceToDecideStartingOrder: response.data.rollDiceToDecideStartingOrder,
                    systemPlayers: response.data.systemPlayers
                };
                this.board = response.data.board;
            });
        } catch (error) {
            console.error(error);
        }
    }

    apiCreateGame = async () => {
        try {
            const response = await axios.get("/create", {
                params: {
                    deviceId: this.deviceId,
                    playerName: this.player.name,
                    playerToken: this.player.token,
                    playerColor: this.player.color
                }
            });
            runInAction(() => {
                if (response.data.success === true) {
                    this.connectToGameSocket(response.data.gameUUID, response.data.gameCode, response.data.playerIndex);
                }
            });
        } catch (error) {
            console.error(error);
        }
    }

    apiJoinGame = async () => {
        console.log(this.gameCode);
        try {
            const response = await axios.get("/join", {
                params: {
                    deviceId: this.deviceId,
                    gameCode: this.gameCode,
                    playerName: this.player.name,
                    playerToken: this.player.token,
                    playerColor: this.player.color
                }
            });
            runInAction(() => {
                if (response.data.success === true) {
                    this.connectToGameSocket(response.data.gameUUID, response.data.gameCode, response.data.playerIndex);
                }
            });
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    connectToGameSocket(gameUUID, gameCode, playerIndex) {
        this.gameUUID = gameUUID;
        this.gameCode = gameCode;
        this.player.index = playerIndex;
        this.currentPlayer = playerIndex;

        localStorage.setItem("playerIndex", playerIndex);
        localStorage.setItem("gameUUID", gameUUID);
        localStorage.setItem("gameCode", gameCode);

        this.players = [];
        this.players.push(this.player);
    }

    reconnectToGameSocket() {
        this.loadInfoFromStorage();
        this.socket.emit("join", this.deviceId, this.gameUUID);
    }

    loadInfoFromStorage() {
        this.deviceId = localStorage.getItem('deviceId');
        this.gameCode = localStorage.getItem('gameCode');
        this.gameUUID = localStorage.getItem('gameUUID');
        this.player.color = localStorage.getItem('playerColor');
        this.player.name = localStorage.getItem('playerName');
        this.player.token = localStorage.getItem('playerToken');
        this.player.index = localStorage.getItem('playerIndex') * 1;

        console.log("loadInfoFromStorage",this.player);
    }

    randomUserParams() {
        this.setRandomName();
        this.setRandomToken();
        this.setRandomColor();
    }

    setName(name) {
        this.player.name = name;
        localStorage.setItem("playerName", this.player.name);
    }

    setColor(color) {
        this.player.color = color;
        localStorage.setItem("playerColor", this.player.color);
    }

    setToken(token) {
        this.player.token = token;
        localStorage.setItem("playerToken", this.player.token);
    }

    setRandomName() {
        const names = ["Kent721", "ARTI.BRO2", "Bikker666", "Genius228", "Pers99", "Blackflex", "GuN68", "Detonator7", "Hook11", "Stalker345", "*RE$PECT*", "Kizill225", "Brodyaga22", "Tigrrr488", "TAZ225", "BuzzBoss44", "huligan888", "De_Bill", "JeySee45", "KaiserII", "pizh_on3", "Petr_1", "pro100chok", "Ramzes", "Stalker", "GudGuy", "Zloy12"];
        this.player.name = names[Math.floor(Math.random() * names.length)];
        localStorage.setItem("playerName", this.player.name);
    }

    setRandomToken() {
        const tokens = ["TK_CROWN", "TK_JEEP", "TK_LION", "TK_PLANE", "TK_SHIP", "TK_CAR"];
        this.player.token = tokens[Math.floor(Math.random() * tokens.length)];
        localStorage.setItem("playerToken", this.player.token);
    }

    setRandomColor() {
        const colors = ["PC_RED", "PC_BLUE", "PC_GREEN", "PC_YELLOW", "PC_MAGENTA", "PC_ORANGE"];
        this.player.color = colors[Math.floor(Math.random() * colors.length)];
        localStorage.setItem("playerColor", this.player.color);
    }

    addToLog = (message) => {
        const log = {time: new Date(), message: message};
        this.logs.push(log);
    };

    actionRollDice = () => {
        this.diceOne = Math.floor(Math.random() * 6) + 1;
        this.diceTwo = Math.floor(Math.random() * 6) + 1;
        this.addToLog(`${this.players[this.currentPlayer].name} выбросил ${this.diceOne} и ${this.diceTwo} = ${this.diceOne + this.diceTwo}`);
    }

    actionEndTurn = () => {
        this.addToLog(`Конец хода ${this.players[this.currentPlayer].name}`);
    }

    actionStartGame = () => {
        this.addToLog(`${this.players[this.currentPlayer].name} начинает игру`);
        this.sendStartGame();
    }

    actionConfirmPlayer = () => {
        this.addToLog(`${this.players[this.currentPlayer].name} подтвердил участие в игре`);
        this.sendAcceptConfiguration();
    }

    processError = (error) => {
        console.log("socket.io error", error);
        this.toastError(error[2]);
    };

    processState = (state) => {
        console.log("socket.io state", state);
        if (!state) {
            return;
        }

        this.gamePhase = state[0].phase;
        this.currentPlayer = state[0].currentPlayer;
        this.diceOne = state[0].dice.die1;
        this.diceTwo = state[0].dice.die2;
        this.players = state[0].players;
    };

    sendAcceptConfiguration = () => {
        const args = [
            this.action.ACTION_ACCEPT_CONFIGURATION,
            this.player.index,
            this.config.systemPlayers.bankPlayer
        ]
        console.log("sendAcceptConfiguration", args);
        this.socket.emit("action", args[0], args[1], args[2]);
    };

    sendPlayerInfo = () => {
        const args = [
            this.action.ACTION_NAME_PLAYER,
            this.player.index,
            this.config.systemPlayers.bankPlayer,
            0, // AI
            this.player.token,
            this.player.color,
            this.player.name
        ]
        console.log("sendPlayerInfo", args);
        this.socket.emit("action", args[0], args[1], args[2], args[3], args[4], args[5], args[6]);
    };

    sendStartGame = () => {
        const args = [
            this.action.ACTION_START_GAME,
            this.player.index,
            this.config.systemPlayers.bankPlayer
        ]
        console.log("sendStartGame", args);
        this.socket.emit("action", args[0], args[1], args[2]);
    };

    processLog = (log) => {
        console.log("socket.io log", log);
    }

    processMessage = (message) => {
        console.log("socket.io message", message);

        switch (message[0]) {
            // NOTIFY_PLEASE_ADD_PLAYERS (134) .......... From: THE BANK To: ALL/NOBODY [A: 0] [B: 0] [C: 0] [D: 0] [E: 0]
            case 134:
                break;

            // NOTIFY_ACTION_COMPLETED (117) .......... From: THE BANK To: ALL/NOBODY [A: 10] [B: 1] [C: 1] [D: 0] [E: 0] Player 1 SUCCESS ACTION_ACCEPT_CONFIGURATION
            // [ 117, 6, 7, 10, 1, 0, 0, 0 ]
            case 117:
                switch (message[3]) {
                    case 10: // ACTION_ACCEPT_CONFIGURATION
                        if (message[4] === 1) { // SUCCESS
                            const i = this.players.findIndex((p)=> { return p.index===message[5] });
                            this.players[i].acceptedConfiguration = !this.players[i].acceptedConfiguration;
                        }
                        break;
                    case 11: // ACTION_START_GAME
                        if (message[4] === 1) { // SUCCESS

                        }
                        break;
                    default:
                        break;
                }
                break;

            // NOTIFY_NUMBER_OF_PLAYERS (82) .......... From: THE BANK To: ALL/NOBODY [A: 1] [B: 0] [C: 0] [D: 0] [E: 0]
            case 82:
                this.numberOfPlayer = message[3];
                break;

            // NOTIFY_NAME_PLAYER (83) .......... From: THE BANK To: ALL/NOBODY [A: 0] [B: TK_JEEP] [C: PC_ORANGE] [D: 0] [E: Petr_1]
            case 83:
                const i = this.players.findIndex((p)=> { return p.index===message[3] });

                if (i !== -1) {
                    this.players[i].token = message[4];
                    this.players[i].color = message[5];
                    this.players[i].name = message[7];
                } else {
                    const player = {
                        index: message[3],
                        cash: undefined,
                        name: message[7],
                        token: message[4],
                        color: message[5],
                        currentSquare: undefined,
                        acceptedConfiguration: false
                    }
                    this.players.push(player);
                }

                if (this.player.index === message[3]) {
                    this.player.token = message[4];
                    this.player.color =  message[5];
                    this.player.name = message[7];
                }

                break;

            default:
                break;
        }
    };
}

const store = new Store();
export default store;
