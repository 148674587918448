import {inject, observer} from "mobx-react";
import {Button, Card} from 'react-bootstrap';
import Dice from "./Dice";

const RollDice = ({store}) => {
    return (
        <Card className="action-card">
            <Card.Header>Бросок кубика</Card.Header>

            <Card.Body>
                <Dice/>
            </Card.Body>

            <Card.Footer>
                <Button variant="primary" onClick={() => {
                    store.actionRollDice();
                }}>Бросить кубики</Button>
            </Card.Footer>
        </Card>
    )
}
export default inject("store")(observer(RollDice));
