import {inject, observer} from "mobx-react";

const Player = ({store, playerNumber}) => {
    const color = store.colors[store.players[playerNumber].color];
    return (
        <div
            className="d-flex justify-content-center align-items-center player"
            style={{
                backgroundColor: color
            }}
        >
            {store.tokens[store.players[playerNumber].token]}
        </div>
    );
}

export default inject("store")(observer(Player));
