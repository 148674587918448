import {inject, observer} from "mobx-react";
import {Button, Card} from 'react-bootstrap';
import Dice from "./Dice";

const EndTurn = ({store}) => {
    return (
        <Card className="action-card">
            <Card.Header>Завершение хода</Card.Header>

            <Card.Body>
                <Dice/>
            </Card.Body>

            <Card.Footer>
                <Button variant="primary" onClick={() => {
                    store.actionEndTurn();
                }}>Завершить ход</Button>
            </Card.Footer>
        </Card>
    )
}
export default inject("store")(observer(EndTurn));
