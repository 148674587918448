import {inject, observer} from "mobx-react";

const Dice = ({store}) => {
    return (
        <div className="d-flex flex-row justify-content-center align-items-center dice-content">
            {store.diceOne === 0 && <i className="bi bi-question-square"/>}
            {store.diceOne === 1 && <i className="bi bi-dice-1-fill"/>}
            {store.diceOne === 2 && <i className="bi bi-dice-2-fill"/>}
            {store.diceOne === 3 && <i className="bi bi-dice-3-fill"/>}
            {store.diceOne === 4 && <i className="bi bi-dice-4-fill"/>}
            {store.diceOne === 5 && <i className="bi bi-dice-5-fill"/>}
            {store.diceOne === 6 && <i className="bi bi-dice-6-fill"/>}
            <div className="space-between-dice"/>
            {store.diceTwo === 0 && <i className="bi bi-question-square"/>}
            {store.diceTwo === 1 && <i className="bi bi-dice-1-fill"/>}
            {store.diceTwo === 2 && <i className="bi bi-dice-2-fill"/>}
            {store.diceTwo === 3 && <i className="bi bi-dice-3-fill"/>}
            {store.diceTwo === 4 && <i className="bi bi-dice-4-fill"/>}
            {store.diceTwo === 5 && <i className="bi bi-dice-5-fill"/>}
            {store.diceTwo === 6 && <i className="bi bi-dice-6-fill"/>}
        </div>
    )
}
export default inject("store")(observer(Dice));
