import {Form} from "react-bootstrap";

const SelectToken = ({token, handleChange, disabled}) => {
    return (
        <>
            <Form.Label htmlFor="selectColor">Фишка</Form.Label>
            <Form.Select id="selectColor" value={token} onChange={(e)=>{handleChange(e.target.value)}} disabled={disabled}>
                <option>Выберите свою фишку</option>
                <option value="TK_CROWN">👑</option>
                <option value="TK_JEEP">🚓</option>
                <option value="TK_LION">🦁</option>
                <option value="TK_PLANE">✈️</option>
                <option value="TK_SHIP">⛵️</option>
                <option value="TK_CAR">🚗</option>
            </Form.Select>
        </>
    );
}

export default SelectToken;
