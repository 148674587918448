import { Row, Col, Button, Form, Container, Card, InputGroup } from 'react-bootstrap';
import {observer, inject} from 'mobx-react';
import { useNavigate } from "react-router-dom";

import SelectColor from "./Start/SelectColor";
import SelectToken from "./Start/SelectToken";
import FormTextName from "./Start/FormTextName";

import { toast } from "react-toastify";

const Start = ({store}) => {
    const navigate = useNavigate();
    const handleClickStart = () => {
        store.apiCreateGame().then(()=> {
            navigate("/lobby");
        });
    }

    const handleClickJoin = async() => {
        const data = await store.apiJoinGame();
        if (data.success === true) {
            navigate("/lobby");
        } else {
            console.log(data.error);
            toast.error("Игра с кодом " + store.gameCode + " не найдена.", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    const handleChangeCode = (value) => {
        store.gameCode = value;
    }

    return (
        <Container style={{ width: '52rem' }} className="p-4">
            <Row>
                <Col>
                    <Card className="text-center">
                        <Card.Header>Опишите себя</Card.Header>
                        <Card.Body>
                            <Row>
                                <Col><FormTextName name={store.player.name} handleChange={(value)=>{store.setName(value)}} /></Col>
                                <Col><SelectToken token={store.player.token} handleChange={(value)=>{store.setToken(value)}} /></Col>
                            </Row>
                            <Row className="m-4 mb-0">
                                <Button variant="primary" onClick={()=>{store.randomUserParams();}}>Выбрать случайно 🤪</Button>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Card className="text-center mt-4">
                        <Card.Header>Начать новую игру</Card.Header>
                        <Card.Body>
                            <Button variant="primary" onClick={handleClickStart}>Новая игра</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card className="text-center mt-4">
                        <Card.Header>Присоединиться к новой игре</Card.Header>
                        <Card.Body>
                            <InputGroup>
                                <Form.Control placeholder="000000" type="text" onChange={(e)=>{handleChangeCode(e.target.value)}} />
                                <Button variant="primary" onClick={handleClickJoin}>Присоединиться</Button>
                            </InputGroup>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default inject("store")(observer(Start));
