import {inject, observer} from "mobx-react";
import React, { useEffect, useRef } from 'react';

const Log = ({store}) => {

    const messagesEndRef = useRef(null);

    useEffect(() => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
    }, [store.logs.length]);

    return (
        <div className="logs-tab mt-4">
            {store.logs.length === 0 && (
                <div>Действий пока не было...</div>
            )}
            {store.logs.map((log, i) => {
                let date = new Date(log.time).toLocaleTimeString();
                return (
                    <div key={i}>
                        {date} {log.message}
                    </div>
                )
            })}
            <div ref={messagesEndRef}></div>
        </div>
    )
}
export default inject("store")(observer(Log));
