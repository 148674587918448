import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";

import * as React from "react";
import { createRoot } from "react-dom/client";
import {
    createBrowserRouter,
    RouterProvider
} from "react-router-dom";

import { Provider } from "mobx-react";
import store from './Suetology/Store';

import Start from './Suetology/Screens/Start';
import Lobby from './Suetology/Screens/Lobby';
import Game from './Suetology/Screens/Game';

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Start/>
    },
    {
        path: "/lobby",
        element: <Lobby/>
    },
    {
        path: "/game",
        element: <Game/>
    },
]);

createRoot(document.getElementById("root")).render(
    <Provider store={store}>
        <ToastContainer />
        <RouterProvider router={router} />
    </Provider>
);
