import Main from './Game/Main';
import Square from "./Game/Square";
import "./Game/assets/board.css";
import "./Game/assets/squares.css";
export default function Game() {
    return (
        <div className="d-flex flex-column main">
            <div className="d-flex flex-column main-box">

                <Main/>

                <div className="d-flex flex-row">
                    <Square position={20}/>
                    <Square bottom={true} position={21}/>
                    <Square bottom={true} position={22}/>
                    <Square bottom={true} position={23}/>
                    <Square bottom={true} position={24}/>
                    <Square bottom={true} position={25}/>
                    <Square bottom={true} position={26}/>
                    <Square bottom={true} position={27}/>
                    <Square bottom={true} position={28}/>
                    <Square bottom={true} position={29}/>
                    <Square position={30}/>
                </div>

                <div className="d-flex flex-row justify-content-between">
                    <Square right={true} position={19}/>
                    <Square left={true} position={31}/>
                </div>

                <div className="d-flex flex-row justify-content-between">
                    <Square right={true} position={18}/>
                    <Square left={true} position={32}/>
                </div>

                <div className="d-flex flex-row justify-content-between">
                    <Square right={true} position={17}/>
                    <Square left={true} position={33}/>
                </div>

                <div className="d-flex flex-row justify-content-between">
                    <Square right={true} position={16}/>
                    <Square left={true} position={34}/>
                </div>

                <div className="d-flex flex-row justify-content-between">
                    <Square right={true} position={15}/>
                    <Square left={true} position={35}/>
                </div>

                <div className="d-flex flex-row justify-content-between">
                    <Square right={true} position={14}/>
                    <Square left={true} position={36}/>
                </div>

                <div className="d-flex flex-row justify-content-between">
                    <Square right={true} position={13}/>
                    <Square left={true} position={37}/>
                </div>

                <div className="d-flex flex-row justify-content-between">
                    <Square right={true} position={12}/>
                    <Square left={true} position={38}/>
                </div>

                <div className="d-flex flex-row justify-content-between">
                    <Square right={true} position={11}/>
                    <Square left={true} position={39}/>
                </div>

                <div className="d-flex flex-row">
                    <Square position={10}/>
                    <Square top={true} position={9}/>
                    <Square top={true} position={8}/>
                    <Square top={true} position={7}/>
                    <Square top={true} position={6}/>
                    <Square top={true} position={5}/>
                    <Square top={true} position={4}/>
                    <Square top={true} position={3}/>
                    <Square top={true} position={2}/>
                    <Square top={true} position={1}/>
                    <Square position={0}/>
                </div>
            </div>
        </div>
    )
}
