import {inject, observer} from "mobx-react";
import { ListGroup } from 'react-bootstrap';
import React from "react";

const Players = ({store}) => {
    return (
        <ListGroup>
            {store.players.map((p, i) => {
                return (
                    <ListGroup.Item key={i}
                                    variant={i === store.currentPlayer ? "warning" : ""}
                                    className="d-flex justify-content-between align-items-start"
                                    style={{borderLeft: `5px solid ${store.colors[store.players[i].color]}`}}>
                        <div>{store.tokens[store.players[i].token]} <small>{p.name}</small></div>
                        <div><small><b>{store.players[i].cash}₽</b></small></div>
                    </ListGroup.Item>
                )
            })}
        </ListGroup>
    )
}
export default inject("store")(observer(Players));
